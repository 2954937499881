var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass: "navbar-menu",
      attrs: {
        size: "lg",
        right: "",
        variant: "link",
        "toggle-class": "text-decoration-none text-secondary",
        "no-caret": ""
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _vm.count_pendings.sol_programas +
              _vm.count_pendings.sol_descuentos
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mr-2",
                      attrs: { pill: "", variant: "danger" }
                    },
                    [
                      _c("small", [
                        _vm._v(
                          _vm._s(
                            _vm.count_pendings.sol_programas +
                              _vm.count_pendings.sol_descuentos
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c("div", [_c("b-icon", { attrs: { icon: "gear" } })], 1)
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-dropdown-text", { staticClass: "header__dropdown" }, [
        _c("span", [_vm._v(_vm._s(this.$store.state.rol) + " ")]),
        _vm._v("Settings ")
      ]),
      _c("b-dropdown-divider"),
      _c(
        "div",
        { staticClass: "item__dropdown" },
        [
          _vm.admin
            ? [
                _vm.configRol.administrador.pestana.pdf.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: {
                            icon: "pencil-square",
                            "aria-hidden": "true"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.pdfTitle")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.users.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/users" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "person", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.users.title")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.pharmacy.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/pharmacy" } },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          staticStyle: { width: "20px" },
                          attrs: {
                            src: require("../assets/images/farmacia-dark.png"),
                            alt: "farmacia"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.pharmacy.title")))
                      ]
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.auth_programs.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/auth" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "ui-checks", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.auth.title")) + " "),
                        _vm.count_pendings.sol_programas
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "ml-2",
                                attrs: { variant: "danger" }
                              },
                              [_vm._v(_vm._s(_vm.count_pendings.sol_programas))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.auth_discounts &&
                _vm.configRol.administrador.pestana.auth_discounts.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/authdos" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "ui-checks", "aria-hidden": "true" }
                        }),
                        _vm._v(
                          _vm._s(_vm.$t("admin.authDiscounts.title")) + " "
                        ),
                        _vm.count_pendings.sol_descuentos
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "ml-2",
                                attrs: { variant: "danger" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.count_pendings.sol_descuentos)
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.trm.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/trm" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "credit-card", "aria-hidden": "true" }
                        }),
                        _vm._v("TRM")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.medicines.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/medicine" } },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          staticStyle: { width: "20px" },
                          attrs: {
                            src: require("../assets/images/Medicine.svg"),
                            alt: "medicine"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.medicines.title")))
                      ]
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.integrator.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/integrator" } },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          staticStyle: { width: "20px" },
                          attrs: {
                            src: require("../assets/images/diferenciadores-icon.svg"),
                            alt: "integrator"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.integrator.title")))
                      ]
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.topCards.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/top-cards" } },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          staticStyle: { width: "20px" },
                          attrs: {
                            src: require("../assets/images/historico-icon.svg"),
                            alt: "top"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.top.title")))
                      ]
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.adminRoles.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/rol" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "people", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.role.title")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.adminSucursales.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/bussiness" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "diagram3", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.bussiness.title")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.adminSucursales.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/adminPrograms" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: {
                            icon: "journal-medical",
                            "aria-hidden": "true"
                          }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.bussiness.programsAdmin")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.branchMatrix &&
                _vm.configRol.administrador.pestana.branchMatrix.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/islas&matriz" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: {
                            icon: "file-spreadsheet",
                            "aria-hidden": "true"
                          }
                        }),
                        _vm._v(
                          _vm._s(_vm.$t("admin.branchMatriz.branch")) +
                            " & " +
                            _vm._s(_vm.$t("admin.branchMatriz.matrix"))
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.adminDiscounts &&
                _vm.configRol.administrador.pestana.adminDiscounts.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/discounts" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "cash", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.discounts.title")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.configRol.administrador.pestana.adminDiscounts &&
                _vm.configRol.administrador.pestana.adminDiscounts.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/adminMac" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "box", "aria-hidden": "true" }
                        }),
                        _vm._v(
                          _vm._s(_vm.$t("admin.bussiness.productRegeneraAdmin"))
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  { attrs: { to: "/admin/ingenes" } },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2 text-secondary",
                      attrs: { icon: "cash", "aria-hidden": "true" }
                    }),
                    _vm._v("Propuesta Ingenes ")
                  ],
                  1
                ),
                _vm.configRol.administrador.pestana.adminSucursales.permiso
                  ? _c(
                      "b-dropdown-item",
                      { attrs: { to: "/admin/bundles" } },
                      [
                        _c("b-icon", {
                          staticClass: "mr-2 text-secondary",
                          attrs: { icon: "bag", "aria-hidden": "true" }
                        }),
                        _vm._v(_vm._s(_vm.$t("admin.bussiness.bundle")))
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pathName === "Welcome" && !_vm.superAdmin && _vm.validate()
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.$t("permissions.msgAdminAccess")))
                    ])
                  : _vm._e(),
                _c("b-dropdown-divider")
              ]
            : _vm._e(),
          _c(
            "b-dropdown-item",
            { on: { click: _vm.logout } },
            [
              _c("b-icon", {
                staticClass: "mr-2 text-secondary",
                attrs: { icon: "box-arrow-left", "aria-hidden": "true" }
              }),
              _vm._v(_vm._s(_vm.$t("menu.Logout")))
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }