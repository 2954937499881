<template>
  <!-- <b-row> -->
    <b-dropdown
      size="lg"
      right
      variant="link"
      toggle-class="text-decoration-none text-secondary"
      no-caret
      class="navbar-menu"
    >
      <template #button-content>
        <b-badge pill variant="danger" class="mr-2" v-if="count_pendings.sol_programas + count_pendings.sol_descuentos"
          ><small>{{ count_pendings.sol_programas + count_pendings.sol_descuentos }}</small></b-badge
        >
        <div>
          <b-icon icon="gear"></b-icon>
        </div>
      </template>
      <b-dropdown-text class="header__dropdown"
        ><span>{{ this.$store.state.rol }} </span>Settings
      </b-dropdown-text>
      <b-dropdown-divider />
      <div class="item__dropdown">
        <template v-if="admin">
          <b-dropdown-item to="/admin" v-if="configRol.administrador.pestana.pdf.permiso"
            ><b-icon icon="pencil-square" aria-hidden="true" class="mr-2 text-secondary" />{{
              $t('admin.pdfTitle')
            }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/users" v-if="configRol.administrador.pestana.users.permiso"
            ><b-icon icon="person" aria-hidden="true" class="mr-2 text-secondary" />{{
              $t('admin.users.title')
            }}</b-dropdown-item
          >

          <b-dropdown-item to="/admin/pharmacy" v-if="configRol.administrador.pestana.pharmacy.permiso"
            ><img src="../assets/images/farmacia-dark.png" alt="farmacia" class="mr-2" style="width: 20px" />{{
              $t('admin.pharmacy.title')
            }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/auth" v-if="configRol.administrador.pestana.auth_programs.permiso"
            ><b-icon icon="ui-checks" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.auth.title') }}
            <b-badge variant="danger" class="ml-2" v-if="count_pendings.sol_programas">{{
              count_pendings.sol_programas
            }}</b-badge></b-dropdown-item
          >
          <b-dropdown-item
            to="/admin/authdos"
            v-if="
              configRol.administrador.pestana.auth_discounts && configRol.administrador.pestana.auth_discounts.permiso
            "
            ><b-icon icon="ui-checks" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.authDiscounts.title') }}
            <b-badge variant="danger" class="ml-2" v-if="count_pendings.sol_descuentos">{{
              count_pendings.sol_descuentos
            }}</b-badge></b-dropdown-item
          >
          <b-dropdown-item to="/admin/trm" v-if="configRol.administrador.pestana.trm.permiso"
            ><b-icon icon="credit-card" aria-hidden="true" class="mr-2 text-secondary"></b-icon>TRM</b-dropdown-item
          >
          <b-dropdown-item to="/admin/medicine" v-if="configRol.administrador.pestana.medicines.permiso"
            ><img src="../assets/images/Medicine.svg" alt="medicine" class="mr-2" style="width: 20px" />{{
              $t('admin.medicines.title')
            }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/integrator" v-if="configRol.administrador.pestana.integrator.permiso"
            ><img src="../assets/images/diferenciadores-icon.svg" alt="integrator" class="mr-2" style="width: 20px" />{{
              $t('admin.integrator.title')
            }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/top-cards" v-if="configRol.administrador.pestana.topCards.permiso"
            ><img src="../assets/images/historico-icon.svg" alt="top" class="mr-2" style="width: 20px" />{{
              $t('admin.top.title')
            }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/rol" v-if="configRol.administrador.pestana.adminRoles.permiso"
            ><b-icon icon="people" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.role.title') }}</b-dropdown-item
          >
          <b-dropdown-item to="/admin/bussiness" v-if="configRol.administrador.pestana.adminSucursales.permiso"
            ><b-icon icon="diagram3" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.bussiness.title') }}</b-dropdown-item
          >

          <b-dropdown-item to="/admin/adminPrograms" v-if="configRol.administrador.pestana.adminSucursales.permiso"
            ><b-icon icon="journal-medical" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.bussiness.programsAdmin') }}</b-dropdown-item
          >
          <b-dropdown-item
            to="/admin/islas&matriz"
            v-if="configRol.administrador.pestana.branchMatrix && configRol.administrador.pestana.branchMatrix.permiso"
            ><b-icon icon="file-spreadsheet" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.branchMatriz.branch') }} & {{ $t('admin.branchMatriz.matrix') }}</b-dropdown-item
          >
          <b-dropdown-item
            to="/admin/discounts"
            v-if="
              configRol.administrador.pestana.adminDiscounts && configRol.administrador.pestana.adminDiscounts.permiso
            "
            ><b-icon icon="cash" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.discounts.title') }}</b-dropdown-item
          >
          <b-dropdown-item
            to="/admin/adminMac"
            v-if="
              configRol.administrador.pestana.adminDiscounts && configRol.administrador.pestana.adminDiscounts.permiso
            "
            ><b-icon icon="box" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.bussiness.productRegeneraAdmin') }}</b-dropdown-item
          >

          <b-dropdown-item
            to="/admin/ingenes"
            
            ><b-icon icon="cash" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >Propuesta Ingenes
          </b-dropdown-item>
          <!--bundle menu desplegable-->
          <b-dropdown-item
            to="/admin/bundles"
            v-if="configRol.administrador.pestana.adminSucursales.permiso"
            ><b-icon icon="bag" aria-hidden="true" class="mr-2 text-secondary"></b-icon
            >{{ $t('admin.bussiness.bundle') }}</b-dropdown-item
          >
          <p v-if="pathName === 'Welcome' && !superAdmin && validate()">{{ $t('permissions.msgAdminAccess')}}</p>
          <b-dropdown-divider />
        </template>

        <b-dropdown-item @click="logout"
          ><b-icon icon="box-arrow-left" aria-hidden="true" class="mr-2 text-secondary" />{{
            $t('menu.Logout')
          }}</b-dropdown-item
        >
        
      </div>
    </b-dropdown>
    <!-- <img :src="imageUser" class="image_menu_user" alt="user image" align-self="end" /> -->
  <!-- </b-row> -->
</template>
<script>
import { initialForm } from './Admin/Rol/initialDataFormRol';
export default {
  name: 'MenuUSer',
  data: () => ({
    imageUser: '',
    admin: null,
    superAdmin: null,
  }),
  mounted() {
    this.imageUser = this.$store.state.userResponse?.picture;
    this.admin = this.$store.state.rol.toLowerCase().includes('admin');
    this.superAdmin = this.$store.state.rol.toLowerCase().includes('super admin');
  },
  computed: {
    pathName() {
      return this.$route.name;
    },
    count_pendings() {
      return this.$store.state.count_pending;
    },
    configRol() {
      return this.$store.state.configRol === null ? initialForm : this.$store.state.configRol;
    },
  },
  methods: {
    validate() {
      for (const key in this.configRol.administrador.pestana) {
        if (Object.hasOwnProperty.call(this.configRol.administrador.pestana, key)) {
          const element = this.configRol.administrador.pestana[key];
          if (element.permiso) return false;
        }
      }
      return true;
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>
<style scoped>
/**** DropDown **** */
.header__dropdown {
  margin: 5px 10px;
  color: rgb(173, 181, 189);
  min-width: 180px;
}
.item__dropdown {
  padding: 0 0.5rem 0 0.5rem;
  font-size: 0.9rem;
  color: rgb(173, 181, 189) !important;
}

.image_menu_user {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  box-shadow: 0 0 6px 1px #00000033;
}
</style>
